import React, { useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { _ACTIONS, _COLORS } from "../../utils/_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { getQueryParamsFromFormData } from "../../utils/formHelper";
import { btn } from "../../utils/_style_constants";

function UserFormFilter({ agences, rowsProfils, rowsUser, setRowsUser, user }) {
  const { handleSubmit, register } = useForm();
  const { token } = React.useContext(UserContext);
  const requestHelper = new RequestHelper(token);

  /* Formulaire et filtre de recherche */
  const [selectedAgences, setSelectedAgences] = useState("");

  const handleChangeAgence = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAgences(typeof value == [] ? value.split(",") : value);
  };

  const [selectedProfil, setSelectedProfil] = useState("");
  const handleChangeProfil = (event) => {
    setSelectedProfil(event.target.value);
  };

  const onSearch = (formData) => {
    const params = getQueryParamsFromFormData(formData);
    // restriction pour les utilisateurs
    const listeAgenceUser = user?.agences;
    let params2 = "";
    let finalparams = "";

    if (user.profil !== "SADMIN") {
      if (listeAgenceUser) {
        listeAgenceUser.map(
          (agence) => (params2 = params2 + "&agences[]=" + agence.id)
        );
      }
    }
    if (user.profil === "USER") {
      params["id"] = user.id;
    }

    for (const [key, value] of Object.entries(params)) {
      finalparams = finalparams + "&" + key + "=" + value;
    }

    requestHelper
      .doRequest(_ACTIONS.GET, "/api/users?" + finalparams + params2)
      .then((response) => {
        if (response.status === 200) {
          setRowsUser(response.data["hydra:member"]);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        container
        columnSpacing={1}
      >
        <Grid item md={2} xs={6}>
          <TextField
            sx={{ width: "90%" }}
            {...register("nom", { required: false })}
            size="small"
            label="Nom"
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{ width: "90%" }}
            {...register("email", { required: false })}
            size="small"
            label="Mail"
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Agences</InputLabel>
            <Select
              label="Agences"
              size="small"
              {...register("agences", { required: false })}
              id="agences"
              value={selectedAgences}
              onChange={handleChangeAgence}
              input={<OutlinedInput label="Name" />}
              MenuProps={""}
            >
              <MenuItem value="" type="bool">
                Tous
              </MenuItem>
              {agences.map((agence, i) => (
                <MenuItem key={i} value={agence["@id"]}>
                  {agence.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Profil</InputLabel>
            <Select
              id="profil"
              size="small"
              {...register("profil", { required: false })}
              value={selectedProfil}
              onChange={handleChangeProfil}
            >
              <MenuItem value="null" type="bool">
                Tous
              </MenuItem>
              {rowsProfils.map((profil, i) => (
                <MenuItem key={i} value={profil["@id"]}>
                  {profil.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Actif</InputLabel>
            <Select {...register("actif", { required: false })} size="small">
              <MenuItem value="" type="bool">
                Tous
              </MenuItem>
              <MenuItem value={true} type="bool">
                Actif
              </MenuItem>
              <MenuItem value={false} type="bool">
                Non actif
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={6}>
          <Button
            sx={{ ...btn(_COLORS.primary, _COLORS.primaryLight), width: "90%" }}
            variant="contained"
            type="submit"
          >
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default UserFormFilter;
