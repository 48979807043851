import React from "react";
import { Grid, Typography } from "@mui/material";
import { _ACTIONS, _COLORS } from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import { DatePicker } from "@mui/x-date-pickers";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import MouseOverPopover from "../forms/MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";
import { createConfirm } from "../../utils/confirm";
import { useNavigate } from "react-router";
import moment from "moment";

function DateSignDisplay({
  setCurrentMandat,
  currentMandat,
  dateDisabled,
  requestHelper,
}) {
  const navigate = useNavigate();
  const handleDateDebChange = (date) => {
    let dateToSend = date?.toLocaleString("fr-FR", {
      timeZone: "Europe/paris",
    });
    if (dateToSend !== "Invalid date") {
      createConfirm(
        "Voulez vous renseigner la date de signature ?" +
          "La signature électronique ne sera plus possible après cette action ?"
      )
        .fire({})
        .then((result) => {
          if (result.isConfirmed) {
            requestHelper
              .doRequest(_ACTIONS.PUT, `/api/mandats/${currentMandat.id}`, {
                dateSignature: dateToSend,
                apiSignProcess: true,
                statut: "/api/statuts/4",
                isManual: true,
              })
              .then(() => {
                setCurrentMandat({
                  ...currentMandat,
                  dateSignature: dateToSend,
                  apiSignProcess: true,
                  statut: "/api/statuts/4",
                });
                navigate("/mandat-vente/" + currentMandat.id);
              });
          }
        });
    }
  };

  return (
    <Grid sx={{ ..._STYLES.sxGridChild }}>
      <Typography
        variant="h5"
        style={{
          fontWeight: "bold",
          color: _COLORS.primary,
          padding: 15,
          display: "flex",
          flexDirection: "row",
        }}
      >
        Date de signature &nbsp;&nbsp;
        <MouseOverPopover
          title={<HelpIcon sx={{ color: _COLORS.black, cursor: "pointer" }} />}
          popoverText=" La date de signature est visible si au moins un des signataires est au format papier, sinon
    la date retenue est celle de la signature du signataire de l’agence. "
        />
      </Typography>
      <Grid
        container
        display={"flex"}
        sx={{ alignItems: "center", paddingLeft: 5, paddingBottom: 5 }}
      >
        <Grid item md={2}>
          <Typography>Date de la derniere signature</Typography>
        </Grid>
        <Grid item md={2}>
          <DatePicker
            sx={{ width: "90%" }}
            label="Date de début"
            size="small"
            format="DD/MM/YYYY"
            value={
              currentMandat?.dateSignature
                ? moment(currentMandat.dateSignature)
                : null
            }
            disabled={dateDisabled}
            onChange={handleDateDebChange}
            slots={{
              openPickerIcon: EditCalendarRoundedIcon,
            }}
            slotProps={{
              textField: {
                size: "small",
              },
              openPickerButton: { color: "secondary" },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DateSignDisplay;
