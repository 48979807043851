import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { _COLORS } from "../../../utils/_constants";
import { btn } from "../../../utils/_style_constants";
import { downloadFile } from "../../../utils/helper";
import { _ACTIONS, _SWAL_ICONS, _MESSAGES } from "../../../utils/_constants";
import { swalErrorsLisHtmlBuilder } from "../../../utils/swalHtmlBuilder";
import Swal from "sweetalert2";
import { createToast } from "../../../utils/toast";
import _ from "lodash";
import { useNavigate } from "react-router";

function BoxActionMandat({
  requestHelper,
  currentMandat,
  refresh,
  disabledSign = false,
}) {
  const navigate = useNavigate();
  //* Process Signature */
  const processSignature = () => {
    // S'il n'y a pas de mandant sélectionné
    if (_.isEmpty(currentMandat)) {
      createToast(
        _SWAL_ICONS.warning,
        "Erreur redirection vers la liste des mandats"
      ).then(navigate("/mandats"));
    } else if (currentMandat.type != "recherche") {
      requestHelper
        .doRequest(_ACTIONS.POST, "/api/test-mandat", currentMandat)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data?.errorsFields?.length > 0
          ) {
            Swal.fire({
              icon: _SWAL_ICONS.warning,
              title: _MESSAGES.warning,
              width: "45%",
              html: swalErrorsLisHtmlBuilder(response.data.errorsFields),
            });
          } else if (response.status === 200) {
            navigate(`/process-sign/${currentMandat.id}`);
          }
        });
    } else {
      navigate(`/process-sign/${currentMandat.id}`);
    }
  };
  /** Create Apercu PDF with watermark  */
  const handleApercu = () => {
    if (_.isEmpty(currentMandat)) {
      createToast(
        _SWAL_ICONS.warning,
        "Erreur redirection vers la liste des mandats"
      ).then(navigate("/mandats"));
    } else {
      requestHelper
        .doRequest(_ACTIONS.POST, "/api/apercu-mandat", currentMandat)
        .then((response) => {
          if (response.file === null) {
            console.log("error");
          }
          if (
            response.status === 200 &&
            response.data?.errorsFields?.length > 0
          ) {
            Swal.fire({
              icon: _SWAL_ICONS.warning,
              title: _MESSAGES.warning,
              width: "45%",
              html: swalErrorsLisHtmlBuilder(response.data.errorsFields),
            });
          } else if (response.status === 200 && response.data?.file) {
            downloadFile("apercu.pdf", response.data.file);
            refresh();
          } else {
            createToast(_SWAL_ICONS.error, response.data.message);
          }
        });
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 5, justifyContent: "center" }}>
      <Button
        sx={btn(_COLORS.success, _COLORS.secondary, "20%")}
        variant="contained"
        onClick={() => navigate("/nav")}
        disabled={disabledSign}
      >
        Annuler
      </Button>
      <Button
        sx={btn(_COLORS.yellow, _COLORS.secondary, "20%")}
        variant="contained"
        onClick={handleApercu}
      >
        Aperçu
      </Button>
      <Button
        sx={btn(_COLORS.primary, _COLORS.primaryLight, "20%")}
        variant="contained"
        onClick={() => refresh()}
        disabled={disabledSign}
      >
        Enregistrer
      </Button>
      <Button
        sx={btn(_COLORS.success, _COLORS.secondary, "20%")}
        variant="contained"
        onClick={processSignature}
        disabled={disabledSign}
      >
        Signer
      </Button>
    </Box>
  );
}

export default BoxActionMandat;
