export const _SWAL_ICONS = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
  question: "question",
};

export const _SWAL_BTN = {
  Confirmer: "Confirmer",
  Annuler: "Annuler",
  Ok: "Ok",
};

export const _MESSAGES = {
  form_error: "Veuillez vérifier les champs du formulaire",
  success: "Opération effectuée avec succès",
  error: "Une erreur est survenue",
  identifiants_invalides: "Identifiants invalides",
  unAuthorized: "Vous n'êtes pas autorisé à accéder à cette ressource",
  notFound: "La ressource demandée n'existe pas",
  emailsent: "Un email vous a été envoyé",
  emailnotsent: "Une erreur est survenue lors de l'envoi de l'email",
  agence_edit_success: "Agence modifiée avec succès",
  agence_edit_error:
    "Une erreur est survenue lors de la modification de l'agence",
  agence_create_success: "Agence créée avec succès",
  agence_create_error:
    "Une erreur est survenue lors de la création de l'agence",
  users_edit_success: "Utilisateur modifié avec succès",
  users_edit_error:
    "Une erreur est survenue lors de la modification de l'utilisateur",
  users_create_success: "Utilisateur créé avec succès",
  users_list_error: "Une erreur est survenue lors de la récupération des utilisateurs",
  users_create_error:
    "Une erreur est survenue lors de la création de l'utilisateur",
  mandat_create_file_success: "Document envoyé avec succès",
  mandat_create_file_error:
    "Une erreur est survenue lors de l'envoi du document",
  mandat_upload_file: "Veuillez sélectionner un document",
  mandat_create_success: "Mandat créé avec succès",
  mandat_create_error: "Une erreur est survenue lors de la création du mandat",
  document_remove_success: "Document supprimé avec succès",
  document_remove_error:
    "Une erreur est survenue lors de la suppression du document",
  mandat_abort_success: "Mandat annulé avec succès",
  indev: "Fonctionnalité en cours de développement",
  loading_error: "Une erreur est survenue lors du chargement des données",
  mandat_tosign_success: "Mandat envoyé à la signature avec succès",
  confirm_update_api_lifeconsent:
    "La configuration API de lifeConsent semble opérationnelle, êtes vous sur de vouloir la modifier ?",
  sign_request_success: "Demande de signature envoyée avec succès",
  sign_request_error: "Une erreur est survenue lors de l'envoi de la demande",
};

export const _SHORTMESSAGES = {
  success: "Succès",
  error: "Erreur",
};

export const _COLORS = {
  primary: "#BE217D",
  primaryLight: "#D163A4",
  primaryUltraLight: "#FAD2E0",
  secondary: "#444B68",
  success: "#4AA3A2",
  info: "#4AA3A2",
  light: "#AAAAAA",
  ultraLight: "#F7F6F6",
  danger: "#F64E60",
  yellow: "#FFC957",
  white: "#FFFFFF",
  black: "#000000",
};

export const _ACTIONS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  POSTFILE: "POSTFILE",
};

export const _FORMATS = {
  input_time: "HH:mm",
  input_date: "yyyy-MM-DD",
  datetime: "YYYY-MM-DDTHH:mm",
  datetime_exact: "YYYY-MM-DDTHH:mm:ss.sssZ",
  date_minutes: "YYYY-MM-DDTHH:mm",
  date_display: "DD/MM/YYYY",
  hours_minutes: "HH:mm",
  month_letters: "MMMM",
  month: "MM",
  pretty: "dddd DD/MM/YYYY",
  pretty_tiny: "ddd DD/MM/YYYY",
  month_year: "MMMM YYYY",
  date_and_time_display: "DD/MM/YYYY - HH:mm",
  date_and_time_display_no_bar: "DD/MM/YYYY HH:mm",
  year: "YYYY",
  day: "DD",
  year_month: "YYYYMM",
  date_jour_letter_display: "DD MMMM YYYY",
  complete_date: "dddd DD MMMM YYYY",
  day_letter_date: "dddd",
  classic: "YYYY-MM-DDTHH:mm:ssZ",
  fileName: "DD_MM_YYYY_HH:mm:ss",
};
 
export const _STATUTCOLORS = {
  B: _COLORS.secondary,
  AS: _COLORS.primary,
  ECS: _COLORS.yellow,
  S: _COLORS.success,
  A: _COLORS.danger,
};

export const _TYPEMANDAT = {
  vente: _COLORS.primary,
  recherche: _COLORS.success,
  avenant: _COLORS.yellow,
};

export const _SIGNE = "Signé"
export const _BROUILLON = "Brouillon"
export const _ANNULE = "Annulé"
export const _A_SIGNER = "A signer"
export const _EN_COURS_SIGNATURE = "En cours de signature"

export const _VIAGER_MIXTE = "Viager Mixte"
export const _VIAGER_LIBRE = "Viager Libre"
export const _VIAGER_COMPTANT = "Vente au comptant"
export const _NUE_PROPRIETE = "Nue-propriété"
export const _VENTE_A_TERME_LIBRE = "Vente à terme libre"
export const _VENTE_A_TERME_OCCUPE = "Vente à terme occupée"
export const _VIAGER_OCCUPE = "Viager occupé"
export const _GESTION_VIAGERE = "Gestion viagère"
export const _VENTE_TRADITIONNELLE = "Vente traditionnelle"
