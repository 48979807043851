import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import { RequestHelper } from "../../utils/requestHelper";
import {
  _ACTIONS,
  _COLORS,
  _GESTION_VIAGERE,
  _MESSAGES,
  _SWAL_ICONS,
  _VIAGER_MIXTE,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import { createToast } from "../../utils/toast";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import VentilationPrix from "../forms/mandats/VentilationPrix";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import Mandant from "../forms/mandats/Mandant";
import DescriptionBienMandat from "../forms/mandats/DescriptionBienMandat";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import AnnexesDocumentaires from "../forms/mandats/AnnexesDocumentaires";
import { UserContext } from "../contexts/UserContext";
import _ from "lodash";
import GestionViagereRemuneration from "../forms/mandats/GestionViagereRemuneration";

function Avenant() {
  const { user } = React.useContext(UserContext);
  const [currentAvenant, setCurrentAvenant] = useState([]);
  const currentIdUrl = useParams();
  const requestHelper = new RequestHelper();
  const [refreshMandat, setRefreshMandat] = useState(0);
  const [indices, setIndices] = useState([]);
  const [users, setUsers] = useState([]);
  const [typeVentes, setTypeVentes] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [typeBiens, setTypeBiens] = useState([]);
  const [typeDocuments, setTypeDocuments] = useState([]);

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  const getAvenantById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentAvenant(response.data);
        } else {
          createToast(_SWAL_ICONS.error, _MESSAGES.loading_error).then(() =>
            Navigate("/mandats")
          );
        }
      });
  };

  const getIndice = () => {
    requestHelper.get("/api/indices").then((response) => {
      setIndices(response.data["hydra:member"]);
    });
  };

  const getUsers = () => {
    // on récupére l'agence du mandat
    let agenceAPi = currentAvenant?.agence ? currentAvenant.agence.id : "";
    // si le mandat est null, on récupére l'agence de l'utilisateur
    if (agenceAPi === "" && !_.isUndefined(user.agencesList)) {
      agenceAPi = user.agencesList;
      // on supprime la derniére virgule
      if (agenceAPi[agenceAPi.length - 1] === ",") {
        agenceAPi = agenceAPi.slice(0, -1);
      }
    }
    requestHelper
      .get("/api/users?agences[]=" + agenceAPi + "&represent=true&actif=true")
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      });
  };

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  useEffect(() => {
    /* GET Mandat by Id */
    getAvenantById();
  }, [refreshMandat]);

  /* GET User Entity */
  useEffect(() => {
    getIndice();
    getTypeDeVente();
    getTypeMandant();
    getUsers();
    getCivilite();
    getTypeDeBien();
    getTypeDocument();
  }, []);

  const getTypeDeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      setTypeVentes(response.data["hydra:member"]);
    });
  };

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeDeBien = () => {
    requestHelper.get("/api/type_biens").then((response) => {
      setTypeBiens(response.data["hydra:member"]);
    });
  };

  const getTypeDocument = () => {
    requestHelper.get("/api/type_documents").then((response) => {
      setTypeDocuments(response.data["hydra:member"]);
    });
  };
  const mandantListNonSignataire = currentAvenant?.mandants?.filter(
    (mandants) =>
      mandants.onlyForSignature === false || mandants.onlyForSignature === null
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid rgb(238, 238, 238)",
          borderRadius: "10px",
          gap: "1rem",
          padding: 4,
          margin: 1,
        }}
      >
        <Grid sx={{ width: "100%" }}></Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          item
          xs={11}
          md={11}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
              paddingBottom: 20,
            }}
          >
            Modification d'un avenant au mandat de vente :{" "}
            {currentAvenant?.agence?.id}
            {currentAvenant?.typeVente?.nomTechnique} {currentAvenant?.numero}
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.success,
              paddingBottom: 20,
            }}
          >
            Avenant #{currentAvenant?.chronoAvenant}
          </Typography>
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild, backgroundColor: "#f7e0eb" }}>
          <VentilationPrix
            register={register}
            errors={errors}
            indices={indices}
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
            requestHelper={requestHelper}
          />

          <Grid container spacing={1} paddingLeft={4}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  color: _COLORS.primary,
                }}
              >
                Rémunération du mandataire
              </Typography>
            </Grid>
            {currentAvenant?.typeVente?.nom === _VIAGER_MIXTE && (
              <RemunerationMandataire
                currentMandat={currentAvenant}
                setCurrentMandat={setCurrentAvenant}
                refresh={refresh}
                register={register}
                field={"occupé"}
              />
            )}
            {currentAvenant?.typeVente?.nom !== _GESTION_VIAGERE && (
              <RemunerationMandataire
                currentMandat={currentAvenant}
                setCurrentMandat={setCurrentAvenant}
                refresh={refresh}
                register={register}
                field={"libre"}
              />
            )}
            {currentAvenant?.typeVente?.nom === _GESTION_VIAGERE && (
              <GestionViagereRemuneration
                register={register}
                errors={errors}
                typeBiens={typeBiens}
                currentMandat={currentAvenant}
                setCurrentMandat={setCurrentAvenant}
                refresh={refresh}
              />
            )}
          </Grid>
        </Grid>
        <BoxActionMandat
          requestHelper={requestHelper}
          currentMandat={currentAvenant}
          refresh={refresh}
        ></BoxActionMandat>
        <CreationMandatVente
          register={register}
          currentMandat={currentAvenant}
          setCurrentMandat={setCurrentAvenant}
          typeVentes={typeVentes}
          errors={errors}
          setTypeVentes={setTypeVentes}
          mode="avenant"
          refresh={refresh}
        ></CreationMandatVente>
        <Mandant
          register={register}
          errors={errors}
          typeMandants={typeMandants}
          setTypeMandants={setTypeMandants}
          currentMandat={currentAvenant}
          setCurrentMandat={setCurrentAvenant}
          civilites={civilites}
          refresh={refresh}
          requestHelper={requestHelper}
          mandantListNonSignataire={mandantListNonSignataire}
        />
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <DescriptionBienMandat
            register={register}
            errors={errors}
            typeBiens={typeBiens}
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <ConditionsParticulieres
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
            register={register}
          />
        </Grid>
        <Grid sx={_STYLES.sxGridChild}>
          <AnnexesDocumentaires
            errors={errors}
            register={register}
            typeDocuments={typeDocuments}
            refresh={refresh}
            currentMandat={currentAvenant}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                >
                  Signatures
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                <Typography>Signataire des mandataires</Typography>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <fieldset disabled={true}>
                      <InputLabel> Signataire :</InputLabel>
                      <Select
                        label="Signataire :"
                        sx={{ border: "none" }}
                        size="small"
                        {...register("signataire", { required: false })}
                        value={
                          currentAvenant?.signataire
                            ? currentAvenant?.signataire?.["@id"]
                            : ""
                        }
                        fullWidth
                        disabled={true}
                      >
                        <MenuItem value="null">Aucun Signataire</MenuItem>
                        {users.map((u, i) => (
                          <MenuItem key={i} value={u["@id"]}>
                            {u.prenom + " " + u.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </fieldset>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Avenant;
