import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS, _MESSAGES, _SWAL_ICONS } from "../../utils/_constants";
import { UserContext } from "../contexts/UserContext";
import { _STYLES } from "../../utils/_style_constants";
import { setCookie } from "../../utils/cookieHelper";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useContext } from "react";
import { useState } from "react";
import { set } from "lodash";

const defaultTheme = createTheme();

/**
 * @returns <Login/>
 */
export default function Login() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const requestHelper = new RequestHelper();
  const { updateUser, updateToken } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(true);

  const onSubmit = (data) => {
    data = {
      email: data.email,
      pass: data.pass,
    };
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/login_check", data)
      .then((response) => {
        if (response.data.user) {
          /** Update Context */
          updateUser(response.data.user);
          updateToken(response.data.token);
          /** Set Cookies */
          setCookie("token", response.data.token, 1);
          window.sessionStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          /** Redirect */
          navigate("/nav");
        }
      });
  };

  const handleForgetPassword = async () => {
    const { value: email } = await Swal.fire({
      title: "Mot de passe oublié",
      input: "email",
      inputLabel: "Votre adresse email",
      inputPlaceholder: "Entrer votre adresse email",
    });

    if (email) {
      Swal.fire(`Email renseigné: ${email}`);
      requestHelper
        .post("/api/reset-password", { email: email })
        .then((response) => {
          if (response.data.code === 200) {
            Swal.fire({
              title: "Email envoyé",
              text: _MESSAGES.emailsent,
              icon: _SWAL_ICONS.success,
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              title: "Erreur",
              text: _MESSAGES.emailnotsent,
              icon: _SWAL_ICONS.error,
              confirmButtonText: "Ok",
            });
          }
        });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.REACT_APP_baseUrl + "/file/img/logo.png"}
              alt=""
            />
            <Typography component="h1" variant="h5">
              Accès à votre liste des mandats
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Veuillez saisir votre adresse e-mail",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Adresse e-mail invalide",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    sx={{ mt: 2 }}
                    label="Email"
                    {...field}
                    error={Boolean(errors.email)}
                    helperText={
                      errors.email?.type === "required" && (
                        <Typography sx={_STYLES.errorMessage}>
                          Votre adresse email est requis
                        </Typography>
                      )
                    }
                  />
                )}
              />

              <Controller
                name="pass"
                control={control}
                defaultValue=""
                rules={{
                  required: "Veuillez saisir votre mot de passe",
                  minLength: {
                    value: 8,
                    message:
                      "Le mot de passe doit comporter au moins 8 caractères",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    sx={{ mt: 2 }}
                    label="Mot de passe"
                    type={showPassword ? "password" : "text"}
                    {...field}
                    error={Boolean(errors.pass)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <Visibility></Visibility>
                          ) : (
                            <VisibilityOff></VisibilityOff>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      errors.pass?.type === "required" && (
                        <Typography sx={_STYLES.errorMessage}>
                          Votre mot de passe est requis
                        </Typography>
                      )
                    }
                  />
                )}
              />
              <Grid container sx={{ display: "flex", alignItems: "baseline" }}>
                <Grid item xs>
                  <Button variant="text" onClick={handleForgetPassword}>
                    Mot de passe oublié ?
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Connexion
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img
            src={process.env.REACT_APP_baseUrl + "/file/img/imgLog.png"}
            alt=""
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
