import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _COLORS, _VIAGER_MIXTE } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

function GestionViagereInfos({
  register,
  errors,
  typeBiens,
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
}) {
  const consLocaux = ["Appartement", "Maison", "Emplacement de stationnement"];
  const consLocauxAnnexes = ["Garage", "Cave"];
  const usages = [
    "Habitation / Viager Occupé",
    "Habitation / Viager Libre",
    "Habitation / Vente à terme Occupée",
    "Habitation / Vente à terme Libre",
  ];

  const [usageListe, setUsageListe] = useState([]);
  const [consistanceLocauxListe, setConsistanceLocauxListe] = useState([]);
  const [consistanceLocauxAnnexesListe, setConsistanceLocauxAnnexesListe] =
    useState([]);

  /** Mise à jour de la consistance */
  const handleChangeConsistance = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentMandat({
      ...currentMandat,
      consistanceLocaux: value.toString(),
    });
    editInstantRequest(
      value.toString(),
      currentMandat["@id"],
      "consistanceLocaux"
    );
  };

  /** Mise à jour de la consistance Annexes */
  const handleChangeConsistanceAnnexes = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentMandat({
      ...currentMandat,
      consistanceLocauxAnnexes: value.toString(),
    });
    editInstantRequest(
      value.toString(),
      currentMandat["@id"],
      "consistanceLocauxAnnexes"
    );
  };

  /** Mise à jour Usage */
  const handleChangeUsage = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentMandat({
      ...currentMandat,
      usageGestionViagere: value.toString(),
    });
    editInstantRequest(
      value.toString(),
      currentMandat["@id"],
      "usageGestionViagere"
    );
  };

  useEffect(() => {
    setConsistanceLocauxAnnexesListe(consLocauxAnnexes);
    setConsistanceLocauxListe(consLocaux);
    setUsageListe(usages);
  }, [refresh]);

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        padding={4}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Description du bien & Usage
          </Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Local principal*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small"> Local principal</InputLabel>
            <Select
              label="Local principal"
              size="small"
              multiple
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.consistanceLocaux
                  ? currentMandat.consistanceLocaux.split(",")
                  : []
              }
              {...register("consistanceLocaux", { required: false })}
              id="consistanceLocaux"
              onChange={handleChangeConsistance}
            >
              {consistanceLocauxListe.map((cons, i) => (
                <MenuItem key={i} value={cons}>
                  {cons}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Local Annexe(s)*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small"> Local Annexe(s)</InputLabel>
            <Select
              label="Local annexe(s)"
              size="small"
              multiple
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.consistanceLocauxAnnexes
                  ? currentMandat.consistanceLocauxAnnexes.split(",")
                  : []
              }
              {...register("consistanceLocauxAnnexes", { required: false })}
              id="consistanceLocauxAnnexes"
              onChange={handleChangeConsistanceAnnexes}
            >
              {consistanceLocauxAnnexesListe.map((cons, i) => (
                <MenuItem key={i} value={cons}>
                  {cons}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Usage*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small"> Usage</InputLabel>
            <Select
              label="Usage"
              size="small"
              multiple
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.usageGestionViagere
                  ? currentMandat.usageGestionViagere.split(",")
                  : []
              }
              {...register("usageGestionViagere", { required: false })}
              id="usageGestionViagere"
              onChange={handleChangeUsage}
            >
              {usageListe.map((cons, i) => (
                <MenuItem key={i} value={cons}>
                  {cons}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Typography>Date de début </Typography>
        </Grid>
        <Grid item md={4}>
          <DatePicker
            label="Date de début"
            size="small"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            value={
              currentMandat && currentMandat.dateDebut
                ? moment(currentMandat.dateDebut)
                : null
            }
            format="DD/MM/YYYY"
            onChange={(date) => {
              let dateToSend = date?.toLocaleString("fr-FR", {
                timeZone: "Europe/paris",
              });
              if (dateToSend !== "Invalid date") {
                editInstantRequest(
                  _.isNull(dateToSend) ? null : dateToSend,
                  currentMandat["@id"],
                  "dateDebut"
                );
                refresh();
              }
            }}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Le Crédirentier*
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Crédirentier"
              size="small"
              id="debitRentier"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.creditRentier ? currentMandat.creditRentier : ""
              }
              onChange={(e) => {
                setCurrentMandat({
                  ...currentMandat,
                  creditRentier: e.target.value,
                });
              }}
              onBlur={(e) => {
                editInstantRequest(
                  e.target.value,
                  currentMandat["@id"],
                  "creditRentier"
                );

                refresh();
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Le Débirentier*
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Débirentier"
              size="small"
              id="debitRentier"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.debitRentier ? currentMandat.debitRentier : ""
              }
              onChange={(e) => {
                setCurrentMandat({
                  ...currentMandat,
                  debitRentier: e.target.value,
                });
              }}
              onBlur={(e) => {
                editInstantRequest(
                  e.target.value,
                  currentMandat["@id"],
                  "debitRentier"
                );

                refresh();
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography noWrap sx={{ ..._STYLES.typographyLabelTitle }}>
            Adresse du bien*
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="adressBien"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            label="Adresse du bien"
            value={
              currentMandat?.bien?.adresse ? currentMandat.bien.adresse : ""
            }
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  adresse: e.target.value,
                },
              });
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "adresse"
              );

              refresh();
            }}
            helperText={
              errors?.biens?.adresse?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Adresse est requis
                </Typography>
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default GestionViagereInfos;
