import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _COLORS, _VIAGER_MIXTE } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";

function DescriptionBienMandat({
  register,
  errors,
  typeBiens,
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
}) {
  const [valeurVenale, setValeurVenale] = useState("");
  const [valeurVenaleOccupee, setValeurVenaleOccupee] = useState("");
  let des =
    currentMandat?.typeBien?.nomTechnique == "M"
      ? "Une maison ........... composée ..."
      : "Un appartement ..............de type T... composé ...";

  useEffect(() => {
    setComposeDe(currentMandat?.bien?.composition);
  }, [currentMandat]);

  const updateValeurBien = (value) => {
    currentMandat.bien.valeurBiens.filter(
      (v) => v.mandat === currentMandat["@id"]
    )[0].valeurVenale = value;
    setCurrentMandat({
      ...currentMandat,
      bien: {
        ...currentMandat.bien,
        valeursBiens: {
          ...currentMandat.bien.valeursBiens,
        },
      },
    });
    setValeurVenale(value);
  };
  const updateValeurBienOccupee = (value) => {
    currentMandat.bien.valeurBiens.filter(
      (v) => v.mandat === currentMandat["@id"]
    )[0].valeurVenaleOccupee = value;
    setCurrentMandat({
      ...currentMandat,
      bien: {
        ...currentMandat.bien,
        valeursBiens: {
          ...currentMandat.bien.valeursBiens,
        },
      },
    });
    setValeurVenaleOccupee(value);
  };

  const [composeDe, setComposeDe] = useState("");

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        padding={4}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Description du bien
          </Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Type de bien*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small"> Type de bien</InputLabel>
            <Select
              label="Type de bien"
              size="small"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.typeBien ? currentMandat?.typeBien["@id"] : ""
              }
              {...register("typeBien", { required: false })}
              id="typeBien"
              onChange={(e) => {
                let m = editInstantRequest(
                  e.target.value,
                  currentMandat["@id"],
                  "typeBien"
                );

                m.then((m) => {
                  setCurrentMandat(m);
                });
              }}
            >
              {typeBiens.map((typeBien, i) => (
                <MenuItem key={i} value={typeBien["@id"]}>
                  {typeBien.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} />
        <Grid item md={2} xs={12}>
          <Typography noWrap sx={{ ..._STYLES.typographyLabelTitle }}>
            Adresse du bien*
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="adressBien"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            label="Adresse du bien"
            value={
              currentMandat?.bien?.adresse ? currentMandat.bien.adresse : ""
            }
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  adresse: e.target.value,
                },
              });
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "adresse"
              );

              refresh();
            }}
            helperText={
              errors?.biens?.adresse?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Adresse est requis
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Parcelle cadastrale
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            size="small"
            fullWidth
            id="cadastre"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            value={
              currentMandat?.bien?.cadastre ? currentMandat.bien.cadastre : ""
            }
            {...register("biens.cadastre", {
              required: false,
            })}
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  cadastre: e.target.value,
                },
              });
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "cadastre"
              );

              refresh();
            }}
          />
        </Grid>
        <Grid item md={6} />
        {currentMandat?.typeVente?.nom !== _VIAGER_MIXTE && (
          <>
            <Grid item md={2} xs={12}>
              <Typography noWrap sx={{ ..._STYLES.typographyLabelTitle }}>
                Composé de*
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                size="large"
                multiline={true}
                rows={5}
                id="composition"
                disabled={currentMandat?.type === "avenant" || disabledSign}
                value={composeDe}
                label="Composition du bien"
                // Besoin de changer la valeur du state pour que le composant se rafraichisse
                onChange={(e) => {
                  setComposeDe(e.target.value);
                }}
                // On envoie la valeur au serveur
                onBlur={(e) => {
                  let m = editInstantRequest(
                    e.target.value,
                    currentMandat.bien["@id"],
                    "composition"
                  );

                  refresh();
                }}
              />
            </Grid>
          </>
        )}

        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Nombre de pièce T
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="appartementT"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            value={
              currentMandat?.bien?.appartementT
                ? currentMandat.bien.appartementT
                : ""
            }
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  appartementT: e.target.value,
                },
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "appartementT"
              );

              refresh();
            }}
            label="Nombre de pièce T ..."
          />
        </Grid>
        {/* METTRE VALEUR VENALE */}
        {/* valeur venal hors Viager Mixte -- */}

        {currentMandat?.type != "avenant" &&
          currentMandat?.typeVente?.nom !== _VIAGER_MIXTE && (
            <>
              <Grid item md={2} xs={12}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  Valeur vénale*
                </Typography>
              </Grid>

              <Grid
                item
                md={4}
                xs={11}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <NumericFormat
                  thousandSeparator=" "
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={2}
                  displayType="input"
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  allowedDecimalSeparators={["."]}
                  size="small"
                  fullWidth
                  id="valeurVenale"
                  label="Valeur Vénale"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  value={
                    currentMandat?.bien?.valeurBiens
                      ? currentMandat.bien.valeurBiens.filter(
                          (v) => v.mandat === currentMandat["@id"]
                        )[0].valeurVenale
                      : ""
                  }
                  {...register("valeurVenale", {
                    onChange: (e) => {
                      updateValeurBien(e.target.value);
                    },
                    onBlur: (e) => {
                      let m = editInstantRequest(
                        e.target.value,
                        currentMandat?.bien?.valeurBiens.filter(
                          (v) => v.mandat === currentMandat["@id"]
                        )[0]["@id"],
                        "valeurVenale"
                      );

                      refresh();
                    },
                    validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                    setValueAs: (value) => {
                      return _.isEmpty(value) ? "" : value;
                    },
                  })}
                />{" "}
                {errors.valeurVenale && (
                  <Typography sx={_STYLES.errorMessage}>
                    Cette valeur doit être une donné numérique
                  </Typography>
                )}
              </Grid>
            </>
          )}

        <Grid sx={{ ..._STYLES.typographyLabelTitle }} item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Tantième globale
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="tantieme"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            value={
              currentMandat?.bien?.tantieme ? currentMandat.bien.tantieme : ""
            }
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  tantieme: e.target.value,
                },
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "tantieme"
              );

              refresh();
            }}
            label="Tantième globale"
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Surface habitable*
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="surface"
            type="number"
            label="Surface habitable"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  m² selon propriétaire
                </InputAdornment>
              ),
            }}
            value={
              currentMandat?.bien?.surface ? currentMandat.bien.surface : ""
            }
            {...register("surface", {
              onChange: (e) => {
                setCurrentMandat({
                  ...currentMandat,
                  bien: {
                    ...currentMandat.bien,
                    surface: e.target.value,
                  },
                });
              },
              onBlur: (e) => {
                let m = editInstantRequest(
                  parseFloat(e.target.value),
                  currentMandat.bien["@id"],
                  "surface"
                );

                refresh();
              },
              validate: (value) => !_.isNaN(value) || _.isEmpty(value),
              setValueAs: (value) => {
                return _.isEmpty(value) ? "" : parseFloat(value);
              },
            })}
          />
        </Grid>
        <Grid item md={12} xs={12} display={"flex"}>
          <Grid item md={2} xs={12}>
            <Typography
              sx={{ ..._STYLES.typographyLabelTitle }}
              display={"flex"}
            >
              Lots
              <MouseOverPopover
                sx={{
                  marginLeft: "10px",
                }}
                title={<HelpIcon />}
                popoverText="Exemple : Lot appartement N°15 portant le numéro 161 du plan des appartements – Lot garage N°89 portant le numéro 7 du plan des garages - Lot cave N°17 portant le numéro 1 du plan des caves. "
              />
            </Typography>
          </Grid>
          <Grid item md={10} xs={8}>
            <TextField
              size="large"
              multiline={true}
              rows={2}
              fullWidth
              id="lot"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={currentMandat?.bien?.lot ? currentMandat.bien.lot : ""}
              onChange={(e) => {
                setCurrentMandat({
                  ...currentMandat,
                  bien: {
                    ...currentMandat.bien,
                    lot: e.target.value,
                  },
                });
              }}
              onBlur={(e) => {
                let m = editInstantRequest(
                  e.target.value,
                  currentMandat.bien["@id"],
                  "lot"
                );

                refresh();
              }}
              label="Lots"
            />
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}></Grid>
        {currentMandat.typeVente &&
          currentMandat.typeVente["@id"] == "/api/type_ventes/7" && (
            <>
              <Grid sx={{ marginTop: "1.5em" }} item md={12} xs={12}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                  variant="h5"
                >
                  Description du bien en Viager Mixte :
                </Typography>
              </Grid>
              <Grid sx={{ marginTop: "1.5em" }} item md={12} xs={12}>
                <Typography
                  style={{
                    color: _COLORS.primary,
                  }}
                  variant="h6"
                >
                  En viager Occupé :
                </Typography>
              </Grid>
              <Grid item md={2} xs={11} display={"flex"}>
                <Typography sx={{ ..._STYLES.typographyLabelTitleNotBold }}>
                  Description du bien :
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  id="descriptionViagerOccupe"
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  multiline={true}
                  rows={5}
                  value={
                    currentMandat?.bien?.descriptionViagerOccupe
                      ? currentMandat.bien.descriptionViagerOccupe
                      : ""
                  }
                  label={des} // Besoin de changer la valeur du state pour que le composant se rafraichisse
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      bien: {
                        ...currentMandat.bien,
                        descriptionViagerOccupe: e.target.value,
                      },
                    });
                  }}
                  // On envoie la valeur au serveur
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat.bien["@id"],
                      "descriptionViagerOccupe"
                    );

                    refresh();
                  }}
                />
              </Grid>
              {/* valeur venal hors Viager Mixte -- */}

              {currentMandat?.type != "avenant" &&
                currentMandat?.typeVente?.nom == _VIAGER_MIXTE && (
                  <>
                    <Grid item md={2} xs={11} display={"flex"}>
                      <Typography
                        sx={{ ..._STYLES.typographyLabelTitleNotBold }}
                      >
                        Valeur vénale*
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <NumericFormat
                        thousandSeparator=" "
                        customInput={TextField}
                        allowNegative={false}
                        decimalScale={2}
                        displayType="input"
                        allowedDecimalSeparators={["."]}
                        size="small"
                        fullWidth
                        disabled={disabledSign}
                        id="valeurVenaleOccupee"
                        label="Valeur Vénale Occupée"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={
                          currentMandat?.bien?.valeurBiens
                            ? currentMandat.bien.valeurBiens.filter(
                                (v) => v.mandat === currentMandat["@id"]
                              )[0].valeurVenaleOccupee
                            : ""
                        }
                        {...register("valeurVenaleOccupee", {
                          onChange: (e) => {
                            updateValeurBienOccupee(e.target.value);
                          },
                          onBlur: (e) => {
                            let m = editInstantRequest(
                              e.target.value,
                              currentMandat?.bien?.valeurBiens.filter(
                                (v) => v.mandat === currentMandat["@id"]
                              )[0]["@id"],
                              "valeurVenaleOccupee"
                            );

                            refresh();
                          },
                          validate: (value) =>
                            !_.isNaN(value) || _.isEmpty(value),
                          setValueAs: (value) => {
                            return _.isEmpty(value) ? "" : value;
                          },
                        })}
                      />{" "}
                      {errors.valeurVenaleOccupee && (
                        <Typography sx={_STYLES.errorMessage}>
                          Cette valeur doit être une donné numérique
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={11} display={"flex"}></Grid>
                  </>
                )}
              <Grid sx={{ marginTop: "1.5em" }} item md={12} xs={12}>
                <Typography
                  style={{
                    color: _COLORS.primary,
                  }}
                  variant="h6"
                >
                  En viager Libre :
                </Typography>
              </Grid>
              <Grid item md={2} xs={11} display={"flex"}>
                <Typography sx={{ ..._STYLES.typographyLabelTitleNotBold }}>
                  Description du bien :
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  id="descriptionViagerLibre"
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  multiline={true}
                  rows={5}
                  value={
                    currentMandat?.bien?.descriptionViagerLibre
                      ? currentMandat.bien.descriptionViagerLibre
                      : ""
                  }
                  label={des}
                  // Besoin de changer la valeur du state pour que le composant se rafraichisse
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      bien: {
                        ...currentMandat.bien,
                        descriptionViagerLibre: e.target.value,
                      },
                    });
                  }}
                  // On envoie la valeur au serveur
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat.bien["@id"],
                      "descriptionViagerLibre"
                    );

                    refresh();
                  }}
                />
              </Grid>
              {currentMandat?.type != "avenant" &&
                currentMandat?.typeVente?.nom == _VIAGER_MIXTE && (
                  <>
                    <Grid item md={2} xs={11} display={"flex"}>
                      <Typography
                        sx={{ ..._STYLES.typographyLabelTitleNotBold }}
                      >
                        Valeur vénale*
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <NumericFormat
                        thousandSeparator=" "
                        customInput={TextField}
                        allowNegative={false}
                        decimalScale={2}
                        displayType="input"
                        allowedDecimalSeparators={["."]}
                        size="small"
                        fullWidth
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        id="valeurVenale"
                        label="Valeur Vénale Libre"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={
                          currentMandat?.bien?.valeurBiens
                            ? currentMandat.bien.valeurBiens.filter(
                                (v) => v.mandat === currentMandat["@id"]
                              )[0].valeurVenale
                            : ""
                        }
                        {...register("valeurVenale", {
                          onChange: (e) => {
                            updateValeurBien(e.target.value);
                          },
                          onBlur: (e) => {
                            let m = editInstantRequest(
                              e.target.value,
                              currentMandat?.bien?.valeurBiens.filter(
                                (v) => v.mandat === currentMandat["@id"]
                              )[0]["@id"],
                              "valeurVenale"
                            );

                            refresh();
                          },
                          validate: (value) =>
                            !_.isNaN(value) || _.isEmpty(value),
                          setValueAs: (value) => {
                            return _.isEmpty(value) ? "" : value;
                          },
                        })}
                      />{" "}
                      {errors.valeurVenale && (
                        <Typography sx={_STYLES.errorMessage}>
                          Cette valeur doit être une donné numérique
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} xs={11} display={"flex"}></Grid>
                  </>
                )}
            </>
          )}
      </Grid>
    </>
  );
}

export default DescriptionBienMandat;
