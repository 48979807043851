import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { _COLORS, _VIAGER_MIXTE } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { NumericFormat } from "react-number-format";

function RemunerationMandataire({
  currentMandat,
  setCurrentMandat,
  refresh,
  register,
  field,
  disabledSign = false,
}) {
  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={4} padding={4}>
        <Grid item xs={3} md={2} alignItems={"center"} display={"flex"}>
          <Typography
            sx={{
              ..._STYLES.typographyLabelTitle,
              ...{ color: _COLORS.primary },
            }}
          >
            Rémunération TTC{" "}
            {currentMandat?.typeVente?.nom === _VIAGER_MIXTE
              ? field === "libre"
                ? "de la partie libre"
                : "de la partie occupé"
              : ""}{" "}
            *
          </Typography>
        </Grid>
        <Grid item xs={9} md={3} display={"flex"} gap={1} alignItems={"center"}>
          <NumericFormat
            thousandSeparator=" "
            allowNegative={false}
            customInput={TextField}
            decimalScale={2}
            displayType="input"
            allowedDecimalSeparators={["."]}
            id={field === "libre" ? "remuneration" : "remunerationOccupee"}
            size="small"
            fullWidth
            disabled={disabledSign}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€ TTC</InputAdornment>
              ),
            }}
            value={
              field === "libre"
                ? currentMandat?.remuneration
                  ? currentMandat?.remuneration
                  : ""
                : currentMandat?.remunerationOccupee
                ? currentMandat?.remunerationOccupee
                : ""
            }
            {...register(
              field === "libre" ? "remuneration" : "remunerationOccupee",
              {
                onChange: (e) => {
                  field === "libre"
                    ? setCurrentMandat({
                        ...currentMandat,
                        remuneration: e.target.value.replace(/ /g, ""),
                      })
                    : setCurrentMandat({
                        ...currentMandat,
                        remunerationOccupee: e.target.value.replace(/ /g, ""),
                      });
                },
                onBlur: (e) => {
                  let m = editInstantRequest(
                    parseFloat(e.target.value.replace(/ /g, "")),
                    currentMandat["@id"],
                    field === "libre" ? "remuneration" : "remunerationOccupee"
                  );

                  m.then((m) => {
                    if (m["@context"] === "/api/contexts/Error") {
                      refresh();
                    } else {
                      setCurrentMandat(m);
                    }
                  });
                },
              }
            )}
          />
        </Grid>
        {currentMandat.type != "recherche" && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={3} md={2} alignItems={"center"} display={"flex"}>
              <Typography>A la charge*</Typography>
            </Grid>
            <Grid item xs={9} md={3}>
              <FormControl fullWidth>
                <InputLabel size="small"> A la charge de</InputLabel>
                <Select
                  label="A la charge de"
                  size="small"
                  disabled={disabledSign}
                  value={
                    field === "libre"
                      ? currentMandat?.remunerationPayeur
                        ? currentMandat?.remunerationPayeur
                        : ""
                      : currentMandat?.remunerationPayeurOccupe
                      ? currentMandat?.remunerationPayeurOccupe
                      : ""
                  }
                  id={
                    field === "libre"
                      ? "remunerationPayeur"
                      : "remunerationPayeurOccupe"
                  }
                  onChange={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat["@id"],
                      field === "libre"
                        ? "remunerationPayeur"
                        : "remunerationPayeurOccupe"
                    );

                    m.then((m) => {
                      if (m["@context"] === "/api/contexts/Error") {
                        refresh();
                      } else {
                        setCurrentMandat(m);
                      }
                    });
                  }}
                >
                  <MenuItem value="vendeur"> Du vendeur</MenuItem>
                  <MenuItem value="acquereur"> De l'acquéreur</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default RemunerationMandataire;
