import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { _ACTIONS, _COLORS, _GESTION_VIAGERE } from "../../../utils/_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NumericFormat } from "react-number-format";
import MandantDetails from "../mandats/MandantDetails";
import MouseOverPopover from "../MouseOverPopover";
import MandantDetailsAutre from "./MandantDetailsAutre";
import _ from "lodash";

function ConditionsParticulieres({
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
  register,
  errors,
  civilites,
  requestHelper,
  typeMandants,
  mandantListSignataire,
}) {
  const [canChangeData, setCanChangeData] = useState(false);
  const [clauseCstVendeur, setClauseCstVendeur] = useState(null);
  const [nbMandantSignataire, setNbMandantSignataire] = useState(0);
  /** Créer un mandant non signataire */
  const createMandantNonSignataire = () => {
    // create mandant
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/mandants", {
        mandats: [currentMandat["@id"]],
        onlyForSignature: true,
      })
      .then((res) => {
        setNbMandantSignataire(1);
        setCurrentMandat({
          ...currentMandat,
          mandants: [...currentMandat.mandants, { ...res.data }],
        });
        let m = editInstantRequest(
          res.data["@id"],
          currentMandat["@id"],
          "consentementVendeur"
        );

        // mets à jour le mandat
        m.then((m) => {
          if (m["@context"] !== "/api/contexts/Error") {
            setCurrentMandat(m);
          }
          refresh();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeConsentementVendeur = (e) => {
    // coche/décoche la case dans le mandat
    if (!_.isUndefined(currentMandat["@id"])) {
      let m = editInstantRequest(
        clauseCstVendeur ? true : false,
        currentMandat["@id"],
        "consentementConjointVendeur"
      );
      // mets à jour le mandat
      m.then((m) => {
        if (m["@context"] !== "/api/contexts/Error") {
          setCurrentMandat(m);
        }
        refresh();
      });
    }
  };

  useEffect(() => {
    // mets à jour la case à cocher
    if (currentMandat) {
      handleChangeConsentementVendeur();
    }
    // si la clause de consentement d'un tiers est cochée, on crée un mandant non signataire
    if (canChangeData) {
      if (clauseCstVendeur) {
        // on supprime le mandant non signataire s'il y en a un
        if (nbMandantSignataire > 0) {
          deleteMandantNonSignataire();
        }
        // on en crée un nouveau
        createMandantNonSignataire();
        // on change le consentement du vendeur
        changeVendor();
        // on rafraichit la page
        refresh();
      } else {
        // on supprime le mandant non signataire s'il y en a un
        if (nbMandantSignataire > 0) {
          deleteMandantNonSignataire();
        }
        refresh();
      }
    }
  }, [clauseCstVendeur]);

  /** Suppression d'un Mandant non signataire */
  const deleteMandantNonSignataire = () => {
    currentMandat?.mandants?.map((mandant) => {
      let m = editInstantRequest(
        null,
        currentMandat["@id"],
        "consentementVendeur"
      );
      m.then((m) => {
        if (m["@context"] !== "/api/contexts/Error") {
          setCurrentMandat(m);
        }
        refresh();
      });
      if (mandant?.onlyForSignature === true) {
        requestHelper
          .doRequest(_ACTIONS.DELETE, mandant["@id"], null)
          .then(setNbMandantSignataire(0));
      }
    });
  };

  useEffect(() => {
    if (currentMandat?.mandants) {
      currentMandat?.mandants.map((mandant, i) => {
        if (mandant?.onlyForSignature === true) {
          setNbMandantSignataire(1);
        } else {
          setNbMandantSignataire(0);
        }
      });
    }
  }, []);

  // changement du signataire pour le consentement du vendeur. Si null, on affiche le formulaire de signataire
  const changeVendor = () => {
    let mandantSignataire = currentMandat?.mandants?.filter(
      (mandants) => mandants.onlyForSignature === true
    );

    let m = editInstantRequest(
      Array.isArray(mandantSignataire) && mandantSignataire[0]?.["@id"]
        ? mandantSignataire[0]?.["@id"]
        : null,
      currentMandat["@id"],
      "consentementVendeur"
    );

    m.then((m) => {
      if (!m["@context"] === "/api/contexts/Error") {
        setCurrentMandat(m);
        refresh();
      }
    });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} display={"flex"}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Conditions particulières&nbsp;&nbsp;
            </Typography>
            <MouseOverPopover
              title={
                <HelpIcon sx={{ color: _COLORS.black, cursor: "pointer" }} />
              }
              popoverText="Exemples de clauses dans le PDF disponible sur Viaginov."
            />
          </Grid>
          {currentMandat?.type != "recherche" &&
          currentMandat?.typeVente?.nom !== _GESTION_VIAGERE ? (
            <>
              <Grid item xs={4} display={"flex"}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          currentMandat?.consentementConjointVendeur
                            ? true
                            : false
                        }
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        onChange={(e) => {
                          setCanChangeData(true);
                          setClauseCstVendeur(!clauseCstVendeur);
                        }}
                      />
                    }
                    label="Clause de consentement d’un tiers."
                    name="consentementConjointVendeur"
                    size="small"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={12}>
                {currentMandat?.mandants
                  ?.filter((mandants) => mandants?.onlyForSignature === true)
                  .map((mandant, i) => {
                    return (
                      <MandantDetails
                        currentMandat={currentMandat}
                        setCurrentMandat={setCurrentMandat}
                        mandant={mandant}
                        i={i}
                        key={i}
                        setModeTutelle={null}
                        modeTutelle={null}
                        register={register}
                        civilites={civilites}
                        refresh={refresh}
                        errors={errors}
                        disabledSign={disabledSign}
                        requestHelper={requestHelper}
                      ></MandantDetails>
                    );
                  })}

                {currentMandat?.consentementConjointVendeur &&
                  currentMandat?.consentementVendeur !== null && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: "2px solid rgb(238, 238, 238)",
                        borderRadius: "10px",
                        borderColor: "#d4bdc8",
                        width: "100%",
                      }}
                    >
                      <MandantDetailsAutre
                        currentMandat={currentMandat}
                        setCurrentMandat={setCurrentMandat}
                        mandant={currentMandat?.consentementVendeur}
                        refresh={refresh}
                        disabledSign={disabledSign}
                        requestHelper={requestHelper}
                      ></MandantDetailsAutre>
                    </Grid>
                  )}
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        currentMandat?.clauseDepartAnticipe ? true : false
                      }
                      disabled={
                        currentMandat?.type === "avenant" || disabledSign
                      }
                      onChange={(e) => {
                        let m = editInstantRequest(
                          e.target.checked,
                          currentMandat["@id"],
                          "clauseDepartAnticipe"
                        );

                        m.then((m) => {
                          if (m["@context"] === "/api/contexts/Error") {
                            refresh();
                          } else {
                            setCurrentMandat(m);
                          }
                        });
                      }}
                    />
                  }
                  label="Clause départ anticipé. Montant de la mensualité à verser par l'acquéreur au profit du vendeur*"
                  name="clauseDepartAnticipe"
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <NumericFormat
                  thousandSeparator=" "
                  allowNegative={false}
                  customInput={TextField}
                  decimalScale={2}
                  displayType="input"
                  allowedDecimalSeparators={["."]}
                  size="small"
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  sx={{ width: "25em" }}
                  label="Votre montant :"
                  value={
                    currentMandat?.clauseDepartAnticipeMontant
                      ? currentMandat?.clauseDepartAnticipeMontant
                      : ""
                  }
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      clauseDepartAnticipeMontant: e.target.value.replace(
                        / /g,
                        ""
                      ),
                    });
                  }}
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      parseFloat(e.target.value.replace(/ /g, "")),
                      currentMandat["@id"],
                      "clauseDepartAnticipeMontant"
                    );

                    m.then((m) => {
                      if (m["@context"] === "/api/contexts/Error") {
                        refresh();
                      } else {
                        setCurrentMandat(m);
                      }
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              {/* /** POUR LA GESTION VIAGERE */}
              {currentMandat?.type != "recherche" && (
                <Grid item xs={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"offert"}
                          checked={currentMandat?.offert ? true : false}
                          disabled={
                            currentMandat?.type === "avenant" || disabledSign
                          }
                          onChange={(e) => {
                            let m = editInstantRequest(
                              e.target.checked ? true : false,
                              currentMandat["@id"],
                              "offert"
                            );

                            m.then((m) => {
                              if (m["@context"] === "/api/contexts/Error") {
                                refresh();
                              } else {
                                setCurrentMandat(m);
                              }
                            });
                          }}
                        />
                      }
                      label="Gestion viagère offerte pendant 1 an"
                      name="offert"
                      size="small"
                    />
                  </FormGroup>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Box sx={{ display: "flex" }}>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentMandat?.autreConditions ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      let m = editInstantRequest(
                        e.target.checked,
                        currentMandat["@id"],
                        "autreConditions"
                      );

                      m.then((m) => {
                        if (m["@context"] === "/api/contexts/Error") {
                          refresh();
                        } else {
                          setCurrentMandat(m);
                        }
                      });
                    }}
                  />
                }
                label="Autres clauses :"
                name="autreCondtions"
                size="small"
              />
            </FormGroup>
          </Grid>
          <Grid paddingTop={"2em"} item xs={8}>
            <CKEditor
              editor={ClassicEditor}
              label="Autre clauses"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              data={
                currentMandat?.autreConditionsText
                  ? currentMandat?.autreConditionsText
                  : ""
              }
              onBlur={(event, editor) => {
                const data = editor.getData();
                let m = editInstantRequest(
                  data,
                  currentMandat["@id"],
                  "autreConditionsText"
                );

                m.then((m) => {
                  if (m["@context"] === "/api/contexts/Error") {
                    refresh();
                  } else {
                    setCurrentMandat(m);
                  }
                });
              }}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ConditionsParticulieres;
