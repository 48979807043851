import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { _COLORS, _VIAGER_OCCUPE } from "../../../utils/_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { NumericFormat } from "react-number-format";

function ModalitePaiement({
  currentMandat,
  setCurrentMandat,
  refresh,
  requestHelper,
  register,
  disabledSign = false,
}) {
  useEffect(() => {}, [currentMandat]);

  // liste des possibilités d'occupation
  const arrayChoics =
    currentMandat?.typeVente?.nom === _VIAGER_OCCUPE
      ? [
          "DUH à vie",
          "DUH à durée déterminée",
          "Usufruit à vie",
          "Usufruit à durée déterminée",
        ]
      : ["Usufruit à vie", "Usufruit à durée déterminée"];
  return (
    <>
      <Typography
        variant="h6"
        fontWeight={"semibold"}
        sx={{
          color: _COLORS.primary,
          padding: "15px 0",
        }}
      >
        Occupation
      </Typography>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth>
          <Grid container sx={{ display: "flex" }}>
            <Grid item md={3}>
              <InputLabel id="typeVenteLabel" size="small">
                Type d'occupation
              </InputLabel>
              <Select
                label="Type d'occupation"
                size="small"
                fullWidth
                disabled={disabledSign}
                value={
                  currentMandat?.modalitePaiement
                    ? currentMandat.modalitePaiement
                    : ""
                }
                {...register("modalitePaiement", {
                  onChange: (e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      modalitePaiement: e.target.value,
                    });
                  },
                  onBlur: (e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat["@id"],
                      "modalitePaiement"
                    );

                    m.then((m) => {
                      if (m["@context"] === "/api/contexts/Error") {
                        refresh();
                      } else {
                        setCurrentMandat(m);
                      }
                    });
                  },
                })}
                id="modalitePaiement"
              >
                <MenuItem value=""></MenuItem>
                {arrayChoics.map((choice, i) => (
                  <MenuItem key={i} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item md={6}>
              {(currentMandat?.modalitePaiement === arrayChoics[1] ||
                currentMandat?.modalitePaiement === arrayChoics[3]) && (
                <NumericFormat
                  label="Durée"
                  thousandSeparator=" "
                  allowNegative={false}
                  customInput={TextField}
                  decimalScale={0}
                  displayType="input"
                  allowedDecimalSeparators={["."]}
                  id={"modalitePaiementDuree"}
                  size="small"
                  disabled={disabledSign}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Ans</InputAdornment>
                    ),
                  }}
                  value={
                    currentMandat?.modalitePaiementDuree
                      ? currentMandat?.modalitePaiementDuree
                      : ""
                  }
                  {...register("modalitePaiementDuree", {
                    onChange: (e) => {
                      setCurrentMandat({
                        ...currentMandat,
                        modalitePaiementDuree: e.target.value.replace(/ /g, ""),
                      });
                    },
                    onBlur: (e) => {
                      let m = editInstantRequest(
                        parseFloat(e.target.value.replace(/ /g, "")),
                        currentMandat["@id"],
                        "modalitePaiementDuree"
                      );

                      m.then((m) => {
                        if (m["@context"] === "/api/contexts/Error") {
                          refresh();
                        } else {
                          setCurrentMandat(m);
                        }
                      });
                    },
                  })}
                />
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </>
  );
}

export default ModalitePaiement;
