import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { editInstantRequest } from "../../../utils/formHelper";
import { createToast } from "../../../utils/toast";
import { NumericFormat } from "react-number-format";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";
import {
  _VENTE_A_TERME_LIBRE,
  _VENTE_A_TERME_OCCUPE,
  _VIAGER_MIXTE,
  _VIAGER_LIBRE,
  _VIAGER_OCCUPE,
  _NUE_PROPRIETE,
} from "../../../utils/_constants";

function Ventilation({
  row,
  i,
  currentMandat,
  setCurrentMandat,
  refresh,
  register,
  requestHelper,
  special = "",
  disabledSign = false,
}) {
  const comptantMode = !_.isNull(row.comptant);

  const mandatVenteOccupee = currentMandat?.typeVente?.nom === _VIAGER_OCCUPE;
  const mandatViagerLibre = currentMandat?.typeVente?.nom === _VIAGER_LIBRE;
  const mandatViagerMixte = currentMandat?.typeVente?.nom === _VIAGER_MIXTE;
  const mandatVenteTermeOccupee =
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE;
  const mandatVenteNuePropriete =
    currentMandat?.typeVente?.nom === _NUE_PROPRIETE;
  const mandatVenteTermeLibre =
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE;

  const label = comptantMode
    ? "Comptant"
    : mandatViagerMixte || mandatViagerLibre || mandatVenteOccupee
    ? "Bouquet"
    : "Mensualités";
  const [indicator, setIndicator] = useState(1);

  /**
   * Pour la gestion des changements dans les ventilations mets à jour le currentMandat
   * @param {*} value
   * @param {*} f
   * @param {*} ventilation
   */
  const handleChange = (value, f, ventilation) => {
    setCurrentMandat({
      ...currentMandat,
      ventilations: currentMandat.ventilations.map((v) => {
        if (v["@id"] === ventilation["@id"]) {
          v[f] = value;
        }
        return v;
      }),
    });
  };
  const handleDurationChange = (ventilation) => {
    setCurrentMandat({
      ...currentMandat,
      ventilations: currentMandat.ventilations.map((v) => {
        if (v["@id"] === ventilation["@id"] && !v["occupationAVie"]) {
          v["duree"] = 0;
        }
        return v;
      }),
    });
    editInstantRequest(0, row["@id"], "duree");
  };

  /**
   * Suppression d'une ventilation
   * @param {*} e
   * @param {*} row
   */
  const deleteVentilation = (e, row) => {
    requestHelper.doRequest("DELETE", row["@id"]).then((res) => {
      if (res.status === 204) {
        createToast("success", "Ventilation supprimée avec succès");
        setCurrentMandat({
          ...currentMandat,
          ventilations: currentMandat.ventilations.filter(
            (v) => v["@id"] !== row["@id"]
          ),
        });
      }
    });
  };

  const formatInputName = (i, type, special = "") => {
    return i + type + special;
  };

  useEffect(() => {
    if (currentMandat?.typeVente?.nom === _VIAGER_MIXTE) {
      setIndicator(2);
    }
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        sx={{ padding: "0.5em" }}
      >
        <Grid item md={5} xs={12} display={"flex"} alignItems={"center"}>
          {/** Checkbox */}
          <FormGroup>
            {(comptantMode &&
              currentMandat?.typeVente?.nom === _VIAGER_OCCUPE) ||
            currentMandat?.typeVente?.nom == _NUE_PROPRIETE ? (
              " "
            ) : (
              <FormControlLabel
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.0rem",
                }}
                control={
                  <Checkbox
                    name={i + "selected" + special}
                    checked={row.selected}
                    disabled={disabledSign}
                    sx={{
                      display:
                        currentMandat?.typeVente?.nom !==
                          _VENTE_A_TERME_LIBRE &&
                        currentMandat?.typeVente?.nom !== _VENTE_A_TERME_OCCUPE
                          ? ""
                          : "none",
                    }}
                    onChange={(e) => {
                      editInstantRequest(
                        e.target.checked,
                        row["@id"],
                        "selected"
                      );

                      refresh();
                    }}
                  />
                }
                label={label + " Net Vendeur "}
              />
            )}
          </FormGroup>

          {/* pour la vente au comptant  et */}
          {comptantMode && (
            <Grid item xs={11} display={"flex"} gap={2} alignItems={"baseline"}>
              {mandatVenteOccupee && (
                <MouseOverPopover
                  title={<HelpIcon />}
                  popoverText="Si cette proposition n'est pas remplie, elle n'apparaîtra pas dans le mandat."
                />
              )}
              <NumericFormat
                thousandSeparator=" "
                customInput={TextField}
                allowNegative={false}
                disabled={disabledSign}
                decimalScale={2}
                displayType="input"
                allowedDecimalSeparators={["."]}
                size="small"
                label="Comptant"
                id={i + "comptant"}
                name={i + "comptant"}
                value={!_.isNull(row.comptant) ? row.comptant : ""}
                {...register(i + "comptant", {
                  onChange: (e) => {
                    // @TODO Update ventilation state
                    handleChange(
                      e.target.value.replace(/ /g, ""),
                      "comptant",
                      row
                    );
                  },
                  onBlur: (e) => {
                    // @TODO Update ventilation server side
                    editInstantRequest(
                      parseFloat(e.target.value.replace(/ /g, "")),
                      row["@id"],
                      "comptant"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value)
                      ? ""
                      : parseFloat(value.replace(/ /g, ""));
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {/* pour les mensualités  */}
          {(mandatVenteTermeLibre ||
            mandatVenteTermeOccupee ||
            mandatVenteNuePropriete) &&
            !comptantMode && (
              <NumericFormat
                thousandSeparator=" "
                customInput={TextField}
                allowNegative={false}
                disabled={disabledSign}
                decimalScale={2}
                displayType="input"
                allowedDecimalSeparators={["."]}
                size="small"
                label="Mensualités"
                id={row["@id"] + "-mensualite"}
                name={i + "mensualite"}
                value={row?.mensualite ? row.mensualite : ""}
                {...register(i + "mensualite", {
                  onChange: (e) => {
                    handleChange(
                      e.target.value.replace(/ /g, ""),
                      "mensualite",
                      row
                    );
                  },
                  onBlur: (e) => {
                    editInstantRequest(
                      parseFloat(e.target.value.replace(/ /g, "")),
                      row["@id"],
                      "mensualite"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value)
                      ? ""
                      : parseFloat(value.replace(/ /g, ""));
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          {/* pour le bouquet  et */}
          {(mandatViagerLibre || mandatViagerMixte || mandatVenteOccupee) &&
            !comptantMode && (
              <NumericFormat
                thousandSeparator=" "
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                disabled={disabledSign}
                displayType="input"
                allowedDecimalSeparators={["."]}
                size="small"
                label="Bouquet"
                id={formatInputName(i, "bouquet", special)}
                value={!_.isNull(row.bouquet) ? row.bouquet : ""}
                {...register(i + "bouquet" + special, {
                  onChange: (e) => {
                    handleChange(
                      e.target.value.replace(/ /g, ""),
                      "bouquet",
                      row
                    );
                  },
                  onBlur: (e) => {
                    editInstantRequest(
                      parseFloat(e.target.value.replace(/ /g, "")),
                      row["@id"],
                      "bouquet"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value)
                      ? ""
                      : parseFloat(value.replace(/ /g, ""));
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
        </Grid>
        <Grid item md={0.5}></Grid>
        <Grid
          item
          md={5}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          {/** Pour la rente viagére */}
          {(mandatViagerLibre || mandatViagerMixte || mandatVenteOccupee) &&
            !comptantMode && (
              <>
                <NumericFormat
                  thousandSeparator=" "
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={2}
                  disabled={disabledSign}
                  displayType="input"
                  allowedDecimalSeparators={["."]}
                  size="small"
                  label="Rente Viagère"
                  id={i + "renteViagere" + special}
                  value={!_.isNull(row.renteViagere) ? row.renteViagere : ""}
                  {...register(i + "renteViagere" + special, {
                    onChange: (e) => {
                      handleChange(
                        e.target.value.replace(/ /g, ""),
                        "renteViagere",
                        row
                      );
                    },
                    onBlur: (e) => {
                      editInstantRequest(
                        parseFloat(e.target.value.replace(/ /g, "")),
                        row["@id"],
                        "renteViagere"
                      );

                      refresh();
                    },
                    validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                    setValueAs: (value) => {
                      return _.isEmpty(value) ? "" : parseFloat(value);
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
                {i > indicator ? (
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={(e) => {
                      //  @TODO Delete ventilation
                      deleteVentilation(e, row);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                ) : null}
              </>
            )}
          {/** Pour la durée */}
          {!comptantMode &&
            (currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE ||
              currentMandat?.typeVente?.nom === _NUE_PROPRIETE ||
              currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE) && (
              <>
                <TextField
                  size="small"
                  label="Durée"
                  name={i + "duree"}
                  disabled={disabledSign}
                  type="number"
                  id={row["@id"] + "duree"}
                  value={row.duree ? row.duree : ""}
                  {...register(i + "duree", {
                    onChange: (e) => {
                      // @TODO Update ventilation state
                      handleChange(e.target.value, "duree", row);
                    },
                    onBlur: (e) => {
                      // @TODO Update ventilation server side
                      editInstantRequest(
                        parseFloat(e.target.value),
                        row["@id"],
                        "duree"
                      );

                      refresh();
                    },
                    validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                    setValueAs: (value) => {
                      return _.isEmpty(value) ? "" : parseFloat(value);
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {currentMandat?.typeVente?.nom !==
                          _VENTE_A_TERME_LIBRE &&
                        currentMandat?.typeVente?.nom !==
                          _VENTE_A_TERME_OCCUPE &&
                        currentMandat?.typeVente?.nom !== _NUE_PROPRIETE
                          ? "mois"
                          : "ans"}
                      </InputAdornment>
                    ),
                  }}
                />
                {label === "Mensualités" &&
                currentMandat?.typeVente?.nom !== _VENTE_A_TERME_OCCUPE &&
                currentMandat?.typeVente?.nom !== _NUE_PROPRIETE &&
                currentMandat?.typeVente?.nom !== _VENTE_A_TERME_LIBRE ? (
                  <IconButton
                    disabled={disabledSign}
                    sx={{ color: "black" }}
                    onClick={(e) => {
                      deleteVentilation(e, row);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                ) : null}
                {i > 1 ? (
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={(e) => {
                      deleteVentilation(e, row);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                ) : null}
              </>
            )}
          {/** Pour l'occupation à vie  */}
          {!comptantMode &&
            (currentMandat?.typeVente?.nom == _VENTE_A_TERME_OCCUPE ||
              currentMandat?.typeVente?.nom == _NUE_PROPRIETE) && (
              <FormGroup>
                <FormControlLabel
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.0rem",
                  }}
                  control={
                    <Checkbox
                      name={i + "selected" + special}
                      checked={row.occupationAVie}
                      disabled={disabledSign}
                      onChange={(e) => {
                        handleDurationChange(row);
                        editInstantRequest(
                          e.target.checked,
                          row["@id"],
                          "occupationAVie"
                        );

                        refresh();
                      }}
                    />
                  }
                  label={" Occupation à vie "}
                />
              </FormGroup>
            )}
        </Grid>
      </Box>
    </>
  );
}

export default Ventilation;
