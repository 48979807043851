import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { _ACTIONS, _COLORS, _VIAGER_OCCUPE } from "../../../utils/_constants";
import { DatePicker } from "@mui/x-date-pickers";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import moment from "moment";
import { createToast } from "../../../utils/toast";
import _ from "lodash";
import { PatternFormat } from "react-number-format";
export const MANDANT_API = "/mandants";

function MandantDetails({
  currentMandat,
  setCurrentMandat,
  refresh,
  register,
  errors,
  civilites,
  disabledSign = false,
  mandant,
  requestHelper,
  i,
}) {
  const handleChangeMandant = (value, f, mandant) => {
    setCurrentMandat({
      ...currentMandat,
      mandants: currentMandat.mandants.map((m) => {
        if (m["@id"] === mandant["@id"]) {
          m[f] = value;
        }
        return m;
      }),
    });
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        key={i}
        sx={{ paddingTop: "1em", paddingBottom: "1em" }}
      >
        <Grid
          item
          md={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              ..._STYLES.typographyLabelTitle,
              ...{ paddingRight: "1em" },
            }}
          ></Typography>
          <FormControl fullWidth>
            <InputLabel size="small">Civilité</InputLabel>
            <Select
              label="Civilité"
              size="small"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={mandant && mandant.civilite ? mandant.civilite["@id"] : ""}
              {...register(i + "civilite", { required: false })}
              id={i + "civilite"}
              onChange={(e) => {
                editInstantRequest(e.target.value, mandant["@id"], "civilite");

                refresh();
              }}
            >
              {civilites?.map((civilite, i) => (
                <MenuItem key={i} value={civilite["@id"]}>
                  {civilite.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Mode Personne */}
        <>
          <Grid item md={1}>
            <TextField
              fullWidth
              size="small"
              id={i + "nom"}
              label="Nom"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={mandant && mandant.nom !== null ? mandant.nom : ""}
              // Besoin de changer la valeur du state pour que le composant se rafraichisse
              onChange={(e) => {
                handleChangeMandant(e.target.value, "nom", mandant);
              }}
              // On envoie la valeur au serveur
              onBlur={(e) => {
                editInstantRequest(e.target.value, mandant["@id"], "nom");

                refresh();
              }}
            />
          </Grid>
          <Grid item md={1}>
            <TextField
              fullWidth
              size="small"
              id={i + "prenom"}
              label="Prénom"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={mandant && mandant.prenom !== null ? mandant.prenom : ""}
              // Besoin de changer la valeur du state pour que le composant se rafraichisse
              onChange={(e) => {
                handleChangeMandant(e.target.value, "prenom", mandant);
              }}
              // On envoie la valeur au serveur
              onBlur={(e) => {
                editInstantRequest(e.target.value, mandant["@id"], "prenom");

                refresh();
              }}
            />
          </Grid>
          <Grid item md={1.5}>
            <DatePicker
              label="Date de naissance"
              size="small"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                mandant && mandant.dateNaissance
                  ? moment(mandant.dateNaissance)
                  : null
              }
              format="DD/MM/YYYY"
              onChange={(date) => {
                let dateToSend = date?.toLocaleString("fr-FR", {
                  timeZone: "Europe/paris",
                });
                if (dateToSend !== "Invalid date") {
                  editInstantRequest(
                    _.isNull(dateToSend) ? null : dateToSend,
                    mandant["@id"],
                    "dateNaissance"
                  );
                  refresh();
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              fullWidth
              size="small"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              id={i + "lieuNaissance"}
              label="Lieu de naissance"
              value={
                mandant && mandant.lieuNaissance !== null
                  ? mandant.lieuNaissance
                  : ""
              }
              // Besoin de changer la valeur du state pour que le composant se rafraichisse
              onChange={(e) => {
                handleChangeMandant(e.target.value, "lieuNaissance", mandant);
              }}
              // On envoie la valeur au serveur
              onBlur={(e) => {
                editInstantRequest(
                  e.target.value,
                  mandant["@id"],
                  "lieuNaissance"
                );

                refresh();
              }}
            />
          </Grid>
        </>
        {/* Partie commune */}
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
          }}
          item
          md={3}
        >
          <TextField
            fullWidth
            size="small"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            id={i + "adresse"}
            label="Adresse"
            value={mandant && mandant.adresse !== null ? mandant.adresse : ""}
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              handleChangeMandant(e.target.value, "adresse", mandant);
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              editInstantRequest(e.target.value, mandant["@id"], "adresse");

              refresh();
            }}
          />
        </Grid>
        <Grid item md={1}>
          <PatternFormat
            format="##.##.##.##.##"
            customInput={TextField}
            fullWidth
            size="small"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            id={i + "portable"}
            name={i + "portable"}
            label="Tel portable"
            value={mandant && mandant.portable !== null ? mandant.portable : ""}
            {...register(i + "portable", {
              onChange: (e) => {
                let stringInput = e.target.value;
                e.target.value = stringInput.replace(/[^0-9]/gi, "");
                handleChangeMandant(e.target.value, "portable", mandant);
              },
              onBlur: (e) => {
                if (errors[i + "portable"]) {
                  createToast(
                    "error",
                    "Il y a une erreur sur ce champ la valeur n'est pas mise à jour"
                  );

                  refresh();
                } else {
                  editInstantRequest(
                    e.target.value,
                    mandant["@id"],
                    "portable"
                  );

                  refresh();
                }
              },
              maxLength: {
                value: 10,
                message: "error message",
              },
              minLength: {
                value: 10,
                message: "error message",
              },
            })}
          />
        </Grid>
        <Grid item md={1}>
          <TextField
            fullWidth
            size="small"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            id={i + "email"}
            name={i + "email"}
            label="Email"
            value={mandant && mandant.email !== null ? mandant.email : ""}
            {...register(i + "email", {
              onChange: (e) => {
                handleChangeMandant(e.target.value, "email", mandant);
              },
              onBlur: (e) => {
                if (errors[i + "email"]) {
                  createToast(
                    "error",
                    "Il y a une erreur sur ce champ la valeur n'est pas mise à jour"
                  );

                  refresh();
                } else {
                  editInstantRequest(e.target.value, mandant["@id"], "email");

                  refresh();
                }
              },
              validate: {
                isEmail: (value) => {
                  return (
                    value === "" || (value.includes("@") && value.includes("."))
                  );
                },
              },
            })}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MandantDetails;
