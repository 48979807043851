import React, { useContext, useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Swal from "sweetalert2";
import LockResetIcon from "@mui/icons-material/LockReset";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { ToggleOff } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, frFR } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BasicModal from "../helpers/BasicModal";
import UserForm from "../forms/UserForm";
import { RequestHelper } from "../../utils/requestHelper";
import { UserContext } from "../contexts/UserContext";
import { _COLORS, _MESSAGES, _SWAL_ICONS } from "../../utils/_constants";
import UserFormFilter from "../forms/UserFormFilter";
import { createConfirm } from "../../utils/confirm";
import { createToast } from "../../utils/toast";
import { _STYLES, btn } from "../../utils/_style_constants";
import { _ACTIONS } from "../../utils/_constants";
import { getAgencesForApi } from "../helpers/Utils";

function ListeUser() {
  const { token } = useContext(UserContext);
  const requestHelper = new RequestHelper(token);
  const [showModal, setShowModal] = React.useState(false);
  const [users, setUsers] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [agences, setAgences] = useState([]);
  const [rowsProfils, setRowsProfils] = useState([]);
  const [rowsUser, setRowsUser] = useState([]);
  const { user, userAgences } = useContext(UserContext);
  const columns = [
    {
      field: "nom",
      headerName: "Nom",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "prenom",
      headerName: "Prénom",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "agences",
      headerName: "Agences",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return params.row.agences?.map((agence) => agence.nom).join(", ");
      },
    },
    {
      field: "email",
      headerName: "Mail",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "profil",
      headerName: "Profil",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return params.row.profil?.nom;
      },
    },
    {
      field: "actif",
      type: "actions",
      headerName: "Actif",
      flex: 1,
      headerClassName: "primaryLight",

      getActions: ({ id }) => {
        const currentUser = rowsUser.find((user) => user.id === id);
        return [
          <IconButton
            label="Activer"
            color="inherit"
            onClick={() => {
              if (user.profil === "SADMIN" || user.profil === "ADMIN")
                handleChangeStatus(id, currentUser?.actif);
            }}
          >
            {currentUser?.actif === true ? (
              <ToggleOnIcon sx={{ color: _COLORS.primary }} />
            ) : (
              <ToggleOff sx={{ color: _COLORS.light }} />
            )}
          </IconButton>,
        ];
      },
    },
    {
      field: "derniereConnexion",
      headerName: "Dernière connexion",
      headerClassName: "primaryLight",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => [
        <strong>
          <EditIcon sx={{ cursor: "pointer" }} onClick={() => doEdit(params)} />
        </strong>,
        <strong key="reset-password">
          {(user.profil === "SADMIN" || user.profil === "ADMIN") && (
            <LockResetIcon
              sx={{ cursor: "pointer", marginLeft: "1rem" }}
              onClick={() => doResetPassword(params)}
            ></LockResetIcon>
          )}
        </strong>,
      ],
    },
  ];

  /* Changement de status */
  const handleChangeStatus = (id, actif) => {
    requestHelper
      .put(`/api/users/${id}`, {
        actif: !actif,
      })
      .then((response) => {
        setRowsUser((prevDataUsers) =>
          prevDataUsers.map((item) =>
            item.id === id ? { ...item, actif: !actif } : item
          )
        );
      })
      .catch((error) => {
        createToast(_MESSAGES.error, _SWAL_ICONS.error);
      });
  };

  /* Récupération des utilisateurs */
  useEffect(() => {
    getRowUtilisateurs();
  }, [refresh]);
  /* Récupération des utilisateurs */
  useEffect(() => {
    getRowUtilisateurs();
  }, []);

  /**
   * Fetches the list of users from the API and updates the state with the response data.
   */
  const getRowUtilisateurs = () => {
    // restriction pour les utilisateurs
    const params = getAgencesForApi(user, "agences");

    requestHelper
      .doRequest(_ACTIONS.GET, "/api/users?" + params)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data["hydra:member"];
          let arrayReturn = [];
          data.map((u) => {
            if (user.profil === "USER" && u.id === user.id) {
              arrayReturn.push(u);
            }
            if (user.profil !== "USER") {
              arrayReturn.push(u);
            }
          });
          setRowsUser(arrayReturn);
        } else {
          createToast(_MESSAGES.users_list_error, _SWAL_ICONS.error);
        }
      });
  };

  /* Ouverture du modal d'edit */
  function doEdit(params) {
    setUsers(params.row);
    setShowModal(true);
  }

  /* Ouverture du modal de reset password */
  async function doResetPassword(params) {
    createConfirm("Etes-vous sûr de vouloir réinitialiser le mot de passe ?")
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .post("/api/reset-password", { email: params.row.email })
            .then((response) => {
              if (response.data.code === 200) {
                Swal.fire({
                  title: "Email envoyé",
                  text: _MESSAGES.emailsent,
                  icon: _SWAL_ICONS.success,
                  confirmButtonText: "Ok",
                });
              } else {
                Swal.fire({
                  title: "Erreur",
                  text: _MESSAGES.emailnotsent,
                  icon: _SWAL_ICONS.error,
                  confirmButtonText: "Ok",
                });
              }
            });
        }
      });
  }

  /* Récupération des agences utile au formulaire */
  useEffect(() => {
    getRowAgences();
  }, [refresh]);

  const getRowAgences = () => {
    const paramAgenceUser = getAgencesForApi(user, "id");
    requestHelper.get("/api/agences?" + paramAgenceUser).then((response) => {
      if (response.status === 200) {
        setAgences(response.data["hydra:member"]);
      }
    });
  };

  /* Récupération des profils utile au formulaire */
  useEffect(() => {
    getRowProfils();
  }, []);

  const getRowProfils = () => {
    requestHelper.get("/api/profils").then((response) => {
      if (response.status === 200) {
        let data = response.data["hydra:member"];
        // Affichage des profils selon le role
        let arrayProfil = [];
        data.map((prof) => {
          if (
            user.profil === "ADMIN" &&
            (prof.nomTechnique === "USER" || prof.nomTechnique === "SUSER")
          ) {
            arrayProfil.push(prof);
          }
          if (user.profil === "SADMIN") {
            arrayProfil.push(prof);
          }
        });
        setRowsProfils(arrayProfil);
      }
    });
  };

  return (
    <Box sx={_STYLES.mainContainer}>
      {/* Filter Form  */}
      <Grid sx={_STYLES.filterFormGrid} rowSpacing={1} container>
        {/** Title */}
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              padding: "15px",
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Recherche utilisateurs
          </Typography>
        </Grid>
        <Grid item x={12}></Grid>
        <Grid item xs={12}>
          <UserFormFilter
            agences={agences}
            rowsProfils={rowsProfils}
            rowsUser={rowsUser}
            setRowsUser={setRowsUser}
            user={user}
          />
        </Grid>
      </Grid>

      {/* Modal */}
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Ajout d'un utilisateur"
        content={
          <UserForm
            agences={agences}
            users={users}
            rowsProfils={rowsProfils}
            refresh={refresh}
            setRefresh={setRefresh}
            setShowModal={setShowModal}
            edit={users}
            setUsers={setUsers}
          />
        }
      />

      {/* Spacer */}
      <Box sx={{ height: "2%" }}></Box>

      {/* Table */}
      <Box sx={_STYLES.secondaryContainer}>
        <Grid sx={{ width: "100%" }} container rowSpacing={1}>
          <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
            <Grid item xs={3}>
              <Typography
                variant="h5"
                style={{
                  padding: "15px",
                  fontWeight: "bold",
                  color: _COLORS.primary,
                }}
              >
                Liste des utilisateurs
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {(user.profil === "SADMIN" || user.profil === "ADMIN") && (
                <Button
                  sx={btn(_COLORS.secondary, _COLORS.success, "100%")}
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                  onClick={() => {
                    setShowModal(true);
                    setUsers(null);
                  }}
                >
                  Ajouter un utilisateur
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xl={12} />

          <Box sx={_STYLES.dataGridContainer}>
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ height: "500px" }}
              rows={rowsUser}
              columns={columns}
              disableSelectionOnClick={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ListeUser;
