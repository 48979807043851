import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  IconButton,
  Select,
  Typography,
  Input,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useEffect, useState } from "react";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
} from "../../../utils/_constants";
import HelpIcon from "@mui/icons-material/Help";
import { _STYLES, btn } from "../../../utils/_style_constants";
import MouseOverPopover from "../MouseOverPopover";
import { RequestHelper } from "../../../utils/requestHelper";
import { createToast } from "../../../utils/toast";
import { useForm } from "react-hook-form";

function AnnexesDocumentaires({
  typeDocuments,
  currentMandat,
  refresh,
  disabledSign = false,
}) {
  const requestHelper = new RequestHelper();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [typeDocumentSelected, setTypeDocumentSelected] = useState("");
  const [documentUploaded, setDocumentUploaded] = useState([]);
  const [currentDocumentByMandat, setCurrentDocumentByMandat] = useState([]);
  const [fileInput, setFileInput] = useState(null);

  const handleFileChange = (event) => {
    setValue("file", event.target.files[0]);
    setFileInput(event.target.files[0]);
  };

  const onSubmit = (data) => {
    if (data.file === undefined) {
      createToast(_SWAL_ICONS.error, _MESSAGES.mandat_upload_file);
      return;
    }

    data["mandat"] = currentMandat["id"];

    requestHelper
      .doRequest(_ACTIONS.POSTFILE, "/api/documents", data)
      .then((response) => {
        if (response.status === 201) {
          createToast(
            _SWAL_ICONS.success,
            _MESSAGES.mandat_create_file_success
          );
          refresh();
        } else {
          createToast(_SWAL_ICONS.error, _MESSAGES.mandat_create_file_error);
        }
      });
  };

  useEffect(() => {
    refresh();
  }, [documentUploaded]);

  /* DELETE Document */

  const handleDeleteDocument = (id) => {
    requestHelper
      .doRequest(_ACTIONS.DELETE, `/api/documents/${id}`)
      .then((response) => {
        if (response.status === 204) {
          createToast(_SWAL_ICONS.success, _MESSAGES.document_remove_success);
          refresh();
        } else {
          createToast(_SWAL_ICONS.error, _MESSAGES.document_remove_error);
        }
      });
  };

  /* Donwload Document */
  const onDownload = (path, name) => {
    path = path.split("public_html/public")[1];
    const link = document.createElement("a");
    link.download = name;
    link.target = "_blank";
    link.href = process.env.REACT_APP_apiUrl + path;
    link.click();
  };

  return (
    <>
      <form id="doc" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} padding={4}>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            gap={2}
            alignItems={"baseline"}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Annexes documentaires
            </Typography>
            <MouseOverPopover
              sx={{
                marginLeft: "10px",
              }}
              title={<HelpIcon />}
              popoverText="Les documents importés et annexés feront partie intégrante du mandat pour signature. 
              (Exemple : jugement de tutelle ou de curatelle, location, contrat d'affichage. La liste est non exhaustive !)"
            />
          </Grid>
          {currentMandat?.type != "avenant" && (
            <>
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h7"
                  sx={{
                    color: _COLORS.primary,
                  }}
                >
                  Importer des fichiers
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small">
                    Choisir un type de document
                  </InputLabel>
                  <Select
                    label="Choisir un type de document"
                    size="small"
                    disabled={disabledSign}
                    value={
                      typeDocumentSelected !== "" ? typeDocumentSelected : ""
                    }
                    {...register("typeDocument", { required: true })}
                    onChange={(e) => {
                      setTypeDocumentSelected(e.target.value);
                    }}
                  >
                    {typeDocuments.map((d, i) => (
                      <MenuItem key={i} value={d["id"]}>
                        {d.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.typeDocument && (
                  <Typography variant="caption" sx={_STYLES.errorMessage}>
                    Ce champ est obligatoire
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} xs={6}>
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={disabledSign}
                  component="label"
                  size="small"
                  sx={{
                    ...btn(_COLORS.success, _COLORS.light),
                    width: "60%",
                    border: "unset",
                  }}
                >
                  <Input onChange={handleFileChange} type="file" name="file" />
                </Button>
                <br></br>
                {errors.file && (
                  <Typography variant="caption" sx={_STYLES.errorMessage}>
                    Veuillez joindre un document
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                display={"flex"}
                justifyContent={"center"}
              >
                <Grid item md={12} xs={6}>
                  <Button
                    disabled={disabledSign}
                    fullWidth
                    variant="contained"
                    type="submit"
                    form="doc"
                    sx={{
                      ...btn(_COLORS.primaryLight, _COLORS.primaryUltraLight),
                    }}
                  >
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} />
          <Grid item md={3} xs={12}>
            <Typography
              variant="h7"
              sx={{
                color: _COLORS.primary,
              }}
            >
              Liste des documents
            </Typography>
          </Grid>
          <Grid item md={9} />
          {currentMandat?.documents?.length > 0 &&
            currentMandat?.documents?.map((doc, i) => {
              return (
                <ListItem key={i}>
                  <Grid item md={12} display={"flex"} gap={2}>
                    <Grid item md={6} display={"flex"} alignItems={"center"}>
                      <IconButton
                        onClick={() => {
                          onDownload(doc.filePath, doc.fileName);
                        }}
                      >
                        <DownloadIcon sx={{ color: _COLORS.primary }} />
                      </IconButton>
                      <ListItemText
                        primary={doc?.type?.nom + " : " + doc.fileName}
                        sx={{ fontWeight: "bold" }}
                      />
                    </Grid>
                    <Grid item md={6} display={"flex"} alignItems={"center"}>
                      <IconButton
                        onClick={() => handleDeleteDocument(doc.id)}
                        disabled={disabledSign}
                      >
                        <DeleteOutlineOutlinedIcon sx={{ color: "black" }} />
                      </IconButton>
                      <ListItemText primary={doc.nom} />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </Grid>
      </form>
    </>
  );
}

export default AnnexesDocumentaires;
